import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApi } from 'sustainment-component';
import {
  Part,
  PartRevision
} from '../components/features/parts/parts-component/parts.component';
import { DrawingParserResult } from '../components/features/parts/create-drawer/new-part.component';

export interface CreateRevisionModel {
  description: string;
  name: string;
  partRevisionFiles: CreatePartRevisionFileModel[];
}

export interface CreatePartRevisionFileModel {
  fileName: string;
  fileSize: number;
  fileId: string;
  fileType: string;
}

@Injectable()
export class PartAPI extends BaseApi {
  protected urlBase: string;
  private _relativeUrl = 'part';
  private _drawingParserUrl = environment.drawingParserUrl;

  public constructor(public _http: HttpClient) {
    super(_http);
    this.urlBase = environment.projectsApiBaseUrl;
  }

  public getAllParts(): Observable<Part[]> {
    return this.get<Part[]>(`${this._relativeUrl}`);
  }

  public getPartById(id: string): Observable<Part> {
    return this.get<Part>(`${this._relativeUrl}/${id}`);
  }

  public addPart(part: Part): Observable<Part> {
    return this.post<Part>(`${this._relativeUrl}`, part);
  }

  public updatePart(part: Part): Observable<Part> {
    return this.put<Part>(`${this._relativeUrl}/`, part);
  }

  public createPartRevision(
    partId: string,
    partRevision: CreateRevisionModel
  ): Observable<PartRevision> {
    return this.post<PartRevision>(
      `${this._relativeUrl}/${partId}/revision`,
      partRevision
    );
  }

  public editPartRevision(
    partId: string,
    revisionId: string,
    partRevision: CreateRevisionModel & {
      partSupportingFiles: CreatePartRevisionFileModel[];
    }
  ): Observable<void> {
    return this.put<void>(
      `${this._relativeUrl}/${partId}/revision/${revisionId}`,
      partRevision
    );
  }

  public runDrawingParser(filename: string, file: string): Observable<DrawingParserResult[][]> {
    const payload = { filename, file };
    return this._http.post<DrawingParserResult[][]>(this._drawingParserUrl, payload);
  }
}
